import { Grid, List, Paper, Typography, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import Tree from '../../components/Tree';
import TreeBreadcrumbs from '../../components/TreeBreadcrumbs';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { useGlobalStyles } from '../../utils/styles';
import { sevacDocuments, sevacFuente, sevacUpdatedAt } from '../../utils/transparencia-difusion/constants';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';


const Sevac = () => {
  const theme = useTheme();
  const globalClasses = useGlobalStyles();
  const [fecha, setFecha] = useState<string>(sevacUpdatedAt);
  const [data, setData] = useState<IListDocs[]>(sevacDocuments); 
  const [breadcrumbs, setBreadcrumbs] = useState<String[]>([]);
  const getTreeBreadcrumbs = (data: String[]) => {
    setBreadcrumbs(data);
  }

  return (
    <Layout>
      <SEO title='SEVAC' />

      <div className={globalClasses.container}>
        <Header title='Sistema de Evaluación de Armonización Contable (SEvAC)' />

        <div className={globalClasses.content}>
          <Typography>Aquí puedes descargar los avances en la Armonización Contable Nacional del Instituto de Seguridad Social del Estado de Tabasco:</Typography>
          <Grid item xs={12}>
            {
                    data?.length ? (
                        <Paper style={{ marginTop: theme.spacing(2) }}>
                            <List disablePadding>
                            {   <>
                                      <TreeBreadcrumbs 
                                        array={breadcrumbs}
                                        setTreeBreadcrumbs={getTreeBreadcrumbs}
                                        //initialArray={"Inicio"}
                                      />
                                      <Tree 
                                          initialArray={data}
                                          activeBreadcrumbs={true}
                                          breadcrumbs={breadcrumbs}
                                          setBreadcrumbs={setBreadcrumbs}
                                      />
                                  </>
                              }
                            </List>
                        </Paper>
                    ): null
                  }


                </Grid>

          <UpdatedInfo fuente={sevacFuente} updatedAt={fecha} />
        </div>
      </div>
    </Layout>
  );
};

export default Sevac;